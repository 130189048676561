@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Edge, & Opera */
  scroll-behavior: smooth;
}

.joker{
  font-family: 'Covered By Your Grace', cursive;
}

.joker2{
  font-family: 'Libre Baskerville', cursive;
 font-weight: 500;
}
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

.brand-scrollbar {
  max-height: 90vh;
  overflow-y: scroll;
  scrollbar-color: #b4d6e4 rgba(217, 217, 217, 0.2);
  scrollbar-width: thin;

  /* Additional styles for cross-browser compatibility */
  /* For Webkit (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 8px; /* Matching the 'thin' scrollbar width */
    color: #b4d6e4;
    scrollbar-color: #b4d6e4;
  }
  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b4d6e4;
    border-radius: 4px; /* Rounded corners for the thumb */
    border: 1px solid rgba(217, 217, 217, 0.2); /* Same color border for seamless look */
    height: 50px; /* This is the height of the thumb */
  }

  /* For IE and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.box-shadow {
  box-shadow: 0px 8px 40px -12px rgba(135, 135, 135, 0.15);
}

/***** ANIMATIONS START *****/
.heart-beat-blue {
  animation: heart-beat-blue 1.5s infinite;
}
@keyframes heart-beat-blue {
  0%,
  50% {
    box-shadow: 0 0 20px 33px rgba(69, 92, 120, 0.31);
  }
}

.heart-beat-purple {
  animation: heart-beat-purple 1.5s infinite;
}
@keyframes heart-beat-purple {
  0%,
  50% {
    box-shadow: 0 0 20px 30px rgba(169, 89, 151, 0.25);
  }
}
.heart-beat-button-purple {
  animation: heart-beat-button-purple 1.5s infinite;
}
@keyframes heart-beat-button-purple {
  0%,
  50% {
    box-shadow: 0 0 20px 20px rgba(183, 140, 228, 0.25);
  }
}
.heart-beat-button-blue {
  animation: heart-beat-button-blue 1.5s infinite;
}
@keyframes heart-beat-button-blue {
  0%,
  50% {
    box-shadow: 0 0 10px 10px rgba(133, 199, 248, 0.19);
  }
}

.drag-over-effect {
  border-color: #4fd1c5 !important; /* Brighter border color for drag over */
  background-color: rgba(
    15,
    15,
    42,
    0.9
  ) !important; /* Slightly lighter background */
  box-shadow: 0 0 15px rgba(79, 209, 197, 0.4); /* Soft glow effect */
  transform: scale(1.02); /* Slight increase in size */
}

/**** ANIMATIONS END *****/

/***** CUSTOM STYLES START *****/
.title-line-height {
  line-height: 20px;
}

@media (max-width: 768px) {
  .title-line-height {
    line-height: 52px;
  }
}
/***** CUSTOM STYLES END *****/

/***** QUILL EDITOR STYLES START*****/
.ql-container,
.ql-toolbar,
.ql-editor {
  border: none !important;
  overflow: hidden !important;
}

.ql-snow .ql-stroke {
  stroke: rgba(217, 155, 255, 0.3);
}
.ql-snow .ql-stroke-miter {
  stroke: rgba(217, 155, 255, 0.3);
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: rgba(217, 155, 255, 0.3);
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button,
.ql-snow.ql-toolbar .ql-picker-label,
.ql-snow .ql-toolbar .ql-picker-label {
  color: rgba(217, 155, 255, 0.3);
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(217, 155, 255, 1);
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(217, 155, 255, 1);
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(217, 155, 255, 1);
}

.ql-editor {
  overflow-y: scroll !important;
  scrollbar-width: none !important; /* Firefox 64+ */
}

.ql-editor::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent; /* make scrollbar transparent */
}
/***** QUILL EDITOR STYLES END*****/

.hero-text {
  width: 100% !important;
}

/***** Modify document scrollbar *****/
html {
  scrollbar-color: #b4d6e4 rgba(217, 217, 217, 0.2);
  scrollbar-width: thin;

  /* Additional styles for cross-browser compatibility */
  /* For Webkit (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 8px; /* Matching the 'thin' scrollbar width */
    color: #b4d6e4;
    scrollbar-color: #b4d6e4;
  }
  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b4d6e4;
    border-radius: 4px; /* Rounded corners for the thumb */
    border: 1px solid rgba(217, 217, 217, 0.2); /* Same color border for seamless look */
    height: 50px; /* This is the height of the thumb */
  }
}
